// TODO: This service needs some TLC and to do less
import * as app from 'durandal/app';
import * as system from 'durandal/system';
import * as ko from 'knockout';
import * as analytics from 'analytics';
import $ from 'jquery';

import '@/Content/lib/jquery/js/jquery.storageapi';

// Long-tail import avoids an import cycle.
import { SupportedAccountingSoftwareType } from '@/features/accounting-software/types/SupportedAccountingSoftware';
import {
  apimHttpHeader,
  ApiService,
  getAuthorizationHeader,
  TOKEN_STORAGE_KEY,
} from '@/features/api';
import { LegacyFeatureFlag } from '@/features/feature-flags/types/LegacyFeatureFlag';
import EventsService from '@/legacy/services/eventsService';
import RealtimeEvent from '@/legacy/services/realtime/models/realtimeEvent';
import RealtimeService from '@/legacy/services/realtime/realtimeService';
import * as plootoUtils from '@/legacy/utils/plooto';
import { PlanName } from '@/legacy/utils/plootoPlan';
import * as viewModels from '@/legacy/viewmodels/viewModels';

class UserProfile {
  id: KnockoutObservable<string> = ko.observable('');

  email: KnockoutObservable<string> = ko.observable('');

  newEmail?: KnockoutObservable<any> = ko.observable();

  fullName: KnockoutObservable<string> = ko.observable('');

  firstName: KnockoutObservable<string> = ko.observable('');

  lastName: KnockoutObservable<string> = ko.observable('');

  emailVerified: KnockoutObservable<boolean> = ko.observable();

  phone?: KnockoutObservable<any> = ko.observable();

  isSignupPhoneVerificationDone?: KnockoutObservable<boolean> = ko.observable();

  country: KnockoutObservable<string> = ko.observable('');

  address?: KnockoutObservable<any> = ko.observable();

  suite?: KnockoutObservable<any> = ko.observable();

  city?: KnockoutObservable<any> = ko.observable();

  zipCode?: KnockoutObservable<any> = ko.observable();

  state?: KnockoutObservable<any> = ko.observable();

  activated: KnockoutObservable<boolean> = ko.observable();

  businessArea?: KnockoutObservable<any> = ko.observable();

  businessAreaOther?: KnockoutObservable<any> = ko.observable();

  businessCategory?: KnockoutObservable<any> = ko.observable();

  businessSubCategory?: KnockoutObservable<any> = ko.observable();

  businessType?: KnockoutObservable<any> = ko.observable();

  businessNumber?: KnockoutObservable<any> = ko.observable();

  workPhone?: KnockoutObservable<any> = ko.observable();

  mobilePhone?: KnockoutObservable<any> = ko.observable();

  occupation?: KnockoutObservable<any> = ko.observable();

  occupationOther?: KnockoutObservable<any> = ko.observable();

  title?: KnockoutObservable<string> = ko.observable('');

  titleOther?: KnockoutObservable<string> = ko.observable('');

  userCapabilities: KnockoutObservableArray<string> = ko.observableArray([]);

  birthdayDay?: KnockoutObservable<any> = ko.observable();

  birthdayMonth?: KnockoutObservable<any> = ko.observable();

  birthdayYear?: KnockoutObservable<any> = ko.observable();

  interestPartOf: KnockoutObservable<string> = ko.observable('');

  registrationDate: KnockoutObservable<XDate> = ko.observable();

  userNameLocked: KnockoutObservable<boolean> = ko.observable();

  gmtHoursOffset: KnockoutObservable<number> = ko.observable();

  chatSecureHash?: KnockoutObservable<any> = ko.observable();

  identityVerificationRequired: KnockoutObservable<boolean> = ko.observable();

  identityVerified: KnockoutObservable<boolean> = ko.observable();

  isIdentitySubmitted: KnockoutObservable<boolean> = ko.observable();

  mfaGracePeriodDaysRemaining: KnockoutObservable<number> = ko.observable(null);

  mfaGracePeriodDays: KnockoutObservable<number> = ko.observable(null);

  icon: any;

  isSsoAdminUser: KnockoutObservable<boolean> = ko.observable();

  ssoAdminCompanyId?: KnockoutObservable<string> = ko.observable(null);

  ssoAdminCompanyName?: KnockoutObservable<string> = ko.observable('');

  isSignupPhoneVerificationRequired: KnockoutObservable<boolean> = ko.observable(null);

  isSingleSignOnSession: KnockoutObservable<boolean> = ko.observable(false);
}

class CompanyMembershipStatsPendingApprovalViewModel {
  public companyId: string;

  public paymentsPendingApprovals: number;
}

class PendingCompanyPaymentReportRow {
  public companyId: string;

  public total: string;
}
class UserValidatedCompanyCapabilityReportRow {
  public companyId: string;
}

class ClientListReportRow {
  public companyId: string;

  public companyVerifications: number;

  public accountVerifications: number;

  public paymentsPendingAction: number;

  public paymentRequestsPendingAction: number;

  public paymentsCreated: number;

  public paymentRequestsCreated: number;

  public paymentsPendingApproval: number;

  public identityVerifications: number;

  public companyCompletedCapabilities: number;

  public isOnPlootoMonthlySubscription: number;

  public hasSubscriptionCancelled: number;

  public numberOfOutstandingVerificationItems: number;

  public wasEverOnPlootoMonthlySubscriptionPlan: boolean;

  public isCurrentlyOnActivePlootoMonthlySubscriptionPlan: boolean;
}

class CompanyBillingCycleViewModel {
  public availableCrossBorder: number;

  public availableDomestic: number;

  public usedCrossBorder: number;

  public usedDomestic: number;

  public usedDomesticRequests: number;

  public hasFreeDomesticRequests: boolean;

  public remainingDomesticTransactions: number;
}

interface CompanySubscriptionFeaturesViewModel {
  subscriptionName: PlanName;

  // Feature limiting introduced by GoPlan(F)
  maxInternalCompanyMembers: number;
  maxAccountingFirmCompanyMembers: number;
  maxBankAccounts: number;
  maxCreditCardAccounts: number;
  maxTransactionAmount: number;
  maxDomesticTransactions: number;

  // Feature locking introduced by GoPlan(F)
  disableCustomPermissions: boolean;
  hasLimitedApprovals: boolean;
  disableIntegrationQuickBooksDesktop: boolean;
  disableAuditTrail: boolean;
  disableLiveSupport: boolean;
  isDualControlsEnabled: boolean;
  isSingleSignOnEnabled: boolean;
  isIntegrationNetSuiteEnabled: boolean;

  // Feature limiting introduced by PPP
  /** When true, the subscription cannot be altered self-serve (e.g. the Partner Plan). */
  isLockedSubscription: boolean;
  /** When false, the the client onboarding promotion should not be displayed. */
  isClientOnboardingBannerVisible: boolean;
  /** When true, the add-on fees will be visible as parent pays opt-in in client settings. */
  isCollectClientAddonFeesVisible: boolean;
}

type UserResetPassphraseValidationModel = {
  userId: string;
  verificationCode: string;
};

type UserResetPassphraseModel = {
  email: string;
};

type UserSetPassphraseEditModel = {
  userId: string;
  verificationCode: string;
  passphrase1: string;
  passphrase2: string;
  recaptchaV2Response?: string;
};

type UserLoginReturnModel = {
  sessionKey: string;
  userId: string;
  lastCompanyId?: string | null;
};

type UserRegistrationReferral = {
  discriminator: string;
};

type UserRegistrationSaaSquatchReferral = UserRegistrationReferral & {
  rsCode: string;
};

type UserRegisterFullModel = {
  firstName: string;
  lastName: string;
  email: string;
  source?: string;
  referral: UserRegistrationSaaSquatchReferral;
  referralId?: string;
  country?: string;
  RecaptchaV2Response?: string;
};

type UserRegisterFullViewModel = UserRegisterFullModel & {
  userId: string;
};

type EmailVerifiedViewModel = {
  email: string;
  loginCode?: string | null;
};

type UserEmailConfirmModel = {
  firstName: string;
  fullName: string;
  lastName: string;
  email: string;
  code: string;
  passphrase1: string;
  passphrase2: string;
  oAuthIdentifier: string;
  oAuthKey: string;
  realmId: string;
};

type UserReSendVerificationModel = {
  email: string;
};

export enum ComputedCompanyStatus {
  PendingCompanyCapabilities = 'PendingCompanyCapabilities',
  ActiveSubscription = 'ActiveSubscription',
  InactiveSubscription = 'InactiveSubscription',
}

const API_PREFIX = 'v1';
class CompanyProfile {
  public id: KnockoutObservable<string> = ko.observable('');

  public name: KnockoutObservable<string> = ko.observable('');

  public operatingName: KnockoutObservable<string> = ko.observable('');

  public address: KnockoutObservable<string> = ko.observable('');

  public city: KnockoutObservable<string> = ko.observable('');

  public suite: KnockoutObservable<string> = ko.observable('');

  public zipCode: KnockoutObservable<string> = ko.observable('');

  public state: KnockoutObservable<string> = ko.observable('');

  public phone: KnockoutObservable<string> = ko.observable('');

  public country: KnockoutObservable<string> = ko.observable('');

  public businessNumber: KnockoutObservable<string> = ko.observable('');

  public businessPhone: KnockoutObservable<string> = ko.observable('');

  public businessCategory: KnockoutObservable<string> = ko.observable('');

  public businessType: KnockoutObservable<string> = ko.observable('');

  public corporationType: KnockoutObservable<string> = ko.observable('');

  public registrationDate: KnockoutObservable<string> = ko.observable('');

  public isAccountingFirm: KnockoutObservable<boolean> = ko.observable(false);

  public shouldUseOperatingNameForDisplay: KnockoutObservable<boolean> = ko.observable(false);

  public businessURL = ko.observable('');

  public integrations = ko.observableArray([]);

  // The always notify here causes the bills/invoices to resync
  public accountingSoftware: KnockoutObservable<SupportedAccountingSoftwareType> = ko
    .observable(undefined)
    .extend({ notify: 'always' });

  public paymentAdded: KnockoutObservable<boolean> = ko.observable(false);

  public profileComplete: KnockoutObservable<boolean> = ko.observable(false);

  public demo: KnockoutObservable<boolean> = ko.observable(false);

  public hasLogo: KnockoutObservable<boolean> = ko.observable(false);

  public logoLastModified: KnockoutObservable<Date> = ko.observable(new Date());

  public freeTransactions: KnockoutObservable<number> = ko.observable(0);

  public lockedName: KnockoutObservable<boolean> = ko.observable(false);

  public settings = ko.observableArray<any>();

  public billingCycle = ko.observable<MappedType<CompanyBillingCycleViewModel>>();

  public subscriptionFeatures: KnockoutObservable<
    MappedType<CompanySubscriptionFeaturesViewModel>
  > = ko.observable<MappedType<CompanySubscriptionFeaturesViewModel>>();

  public hasMaxDomesticTransactions = ko.pureComputed<boolean>(() => {
    const maxDomesticTransactions = this.subscriptionFeatures()?.maxDomesticTransactions();
    return !!maxDomesticTransactions || maxDomesticTransactions === 0;
  });

  public hasLimitedDomesticTransactions = ko.pureComputed<boolean>(() => {
    const remainingDomesticTransactions = this.billingCycle()?.remainingDomesticTransactions();
    const hasRemainingDomesticTransactions =
      !!remainingDomesticTransactions || remainingDomesticTransactions === 0;
    return this.hasMaxDomesticTransactions() && hasRemainingDomesticTransactions;
  });

  public hasRemainingDomesticTransactions = ko.pureComputed<boolean>(() => {
    if (!this.hasMaxDomesticTransactions()) {
      return true;
    }

    const remainingDomesticTransactions = this.billingCycle()?.remainingDomesticTransactions();
    if (!remainingDomesticTransactions && remainingDomesticTransactions !== 0) {
      return true;
    }

    return remainingDomesticTransactions !== 0;
  });

  public companyType: KnockoutObservable<string> = ko.observable('');

  public hasMadeTransactions: KnockoutObservable<boolean> = ko.observable<boolean>();

  public isCreditCardOptionAvailable = ko.observable<boolean>();

  public creditCardPaymentsCapabilityStatus = ko.observable<string>(null);

  public isCompanyVerificationMandatory = ko.observable<boolean>();

  public accountingFirmCompanyId = ko.observable<string>(null);

  public companyAccountingFirmClientId = ko.observable<string>(null);

  public isOnFreeTrial = ko.observable<boolean>(false);

  public freeTrialDaysRemaining = ko.observable<number>(0);

  public freeTrialNextDebitDate = ko.observable<string>(null);

  public reactivationFreeTrialExpiry = ko.observable<string>(null);

  public isOnActivePlootoMonthlySubscriptionPlan = ko.observable<boolean>(false);

  public corporationNumber: KnockoutObservable<string> = ko.observable<string>(null);

  public corporationNumberType: KnockoutObservable<string> = ko.observable<string>(null);

  public corporationNumberProvince: KnockoutObservable<string> = ko.observable<string>(null);

  public taxId: KnockoutObservable<string> = ko.observable<string>(null);

  public onboardingNotLinkingAccountingSoftware: KnockoutObservable<boolean> =
    ko.observable<boolean>(null);

  public onboardingCompletedTime = ko.observable<string>(null);

  public onboardingCompletedTimeForCompliance = ko.observable<string>(null);

  public featuresInTesting = ko.observableArray<any>([]);

  public hasApprovedLimitedDomesticPaymentsCapability = ko.observable<boolean>(null);

  public hasApprovedDomesticPaymentsCapability = ko.observable<boolean>(null);

  public isAnyBankAccountVerified = ko.observable<boolean>(null);

  public bankAdded = ko.observable<boolean>(null);

  public isSubjectToPersonaOnboarding = ko.observable<boolean>(null);

  public isCreditCardPayableEnabled = ko.observable<boolean>(null);

  public isBillingItemsVisible = ko.observable<boolean>(null);

  public computedCompanyStatus = ko.observable<ComputedCompanyStatus>();

  constructor(info: any) {
    // @ts-expect-error: 'this' is not assignable to parameter of type 'KnockoutObservable<any>'
    ko.mapping.fromJS(info, {}, this);
  }

  public Update(info) {
    for (const property in info) {
      // if updated data doesnt specify property skip
      if (info[property] !== undefined) {
        const newValue = ko.utils.unwrapObservable(info[property]);

        switch (property) {
          case 'settings':
          case 'featuresInTesting':
          case 'billingCycle':
          case 'subscriptionFeatures': {
            const koSettingValue = ko.mapping.fromJS(info[property], {}, this[property]);
            break;
          }
          default:
            if (!ko.isComputed(this[property]) && ko.isObservable(this[property])) {
              this[property](newValue);
            }
            break;
        }
      }
    }
  }

  public isCompanyPartnership() {
    return (
      (this.corporationType() &&
        (this.corporationType().toLowerCase() == 'partnership' ||
          this.corporationType().toLowerCase() == 'partnership (gp, lp, llp)')) ||
      (this.businessType() && this.businessType().toLowerCase() == 'partnership')
    );
  }

  public isFeatureEnabled(featureName: LegacyFeatureFlag) {
    return plootoUtils.IsFeatureEnabledForCompanyProfile(this, featureName);
  }
}

interface IPlootoApiCall {
  error: boolean;
  documentation: string;
}
interface IAuthenticationTokenData extends IPlootoApiCall {
  sessionKey: string;
  lastCompanyId: string;
  userId: string;
}
interface IUserLoginResult {
  userLoginResponse: UserLoginResponse;
  tokenData: IAuthenticationTokenData;
}

enum UserLoginResponse {
  Successful = 0,
  TwoFactorAuthenticationRequired,
  TwoFactorSignupRequired,
  TwoFactorComplianceRequired,
}

interface FeaturesInTesting {
  active: string;
  name: string;
}

interface IRegistrationCheck {
  firstName: string;
  lastName: string;
  companyOperatingName: string;
  source: string;
}

class Membership {
  public id: KnockoutObservable<string> = ko.observable('');

  public permissions: KnockoutObservable<Array<string>> = ko.observable([]);

  public role: KnockoutObservable<string> = ko.observable('');

  public company: KnockoutObservable<CompanyProfile> = ko.observable(undefined);

  public outstandingEmailMoneyTransfers = ko.observable<number>(0);

  public paymentsRequringApprovals = ko.observable<number>(0);

  public staffAssigned = ko.observable<number>(0);

  public pendingFundAccounts = ko.observable<number>(0);

  public featuresInTesting = ko.observableArray<FeaturesInTesting>();

  public isPaymentsCapable = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public hasCompanyCapabilities = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public isFastPaymentCapable = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public isPaymentsQueueable = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public isPendingBillingRedebit = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public mustCoverNegativeWalletBalance = ko.observable<boolean>(false); // @todo Michael move this into company(CompanyProfile) observable

  public isReauthenticationRequired = ko.observable<boolean>(false);

  public companyAccountingFirmClientUserId = ko.observable<string>(null);

  public roleDisplay: KnockoutComputed<string> = ko.computed(() =>
    Membership.GetRoleDisplay(this.role())
  );

  public static GetRoleDisplay(role) {
    const roleValue = role;
    if (roleValue === 'admin') {
      return 'Administrator';
    }
    if (roleValue === 'accountant') {
      return 'Accountant';
    }
    if (roleValue === 'cfo') {
      return 'CFO';
    }
    if (roleValue === 'viewer') {
      return 'Viewer';
    }
    if (roleValue === 'custom') {
      return 'Custom Permissions';
    }
    return 'Unknown';
  }

  public static GetPermissionDisplay(permission) {
    switch (permission) {
      case 'payee_add_edit':
        return 'Edit payees';
      case 'bank_account_add_edit':
        return 'Edit bank accounts';
      case 'approval_policy_add_edit':
        return 'Edit approval policies';
      case 'user_add_edit':
        return 'Edit payees';
      case 'payment_add_edit':
        return 'Edit payments';
      case 'document_publish':
        return 'Publish Documents from Capture';
      case 'payment_approve':
        return 'Approve payments';
      case 'company_edit':
        return 'Edit company profile';
    }
    return undefined;
  }

  public Update(membershipInfo) {
    for (const property in membershipInfo) {
      // if updated data doesnt specify property skip
      if (membershipInfo[property] !== undefined) {
        const newValue = ko.utils.unwrapObservable(membershipInfo[property]);

        if (property === 'company') {
          if (!this.company()) {
            this.company(new CompanyProfile(newValue));
          } else {
            const companyProfile = this.company();
            companyProfile.Update(newValue);
          }
        } else if (!ko.isComputed(this[property]) && ko.isObservable(this[property])) {
          this[property](newValue);
        }
      }
    }
  }
}

class UserService {
  private tokenStorage: JQueryStorageStatic;

  private email: string;

  public profile = ko.observable<UserProfile>(undefined);

  public memberships = ko.observableArray<Membership>();

  public accountingFirmMemberships = ko.observableArray<Membership>();

  public isSsoAdminUserWithoutNodePermissions = ko.pureComputed<boolean>(
    () => this.profile()?.isSsoAdminUser?.() && this.memberships().length === 0
  );

  constructor() {
    this.tokenStorage = $.localStorage;

    const sub = app.on(RealtimeEvent.Membership).then((membershipId) => {
      this.LoadMemberships();
      this.loadAccountingFirmMemberships();
    }, this);

    const subUser = app.on('realtime:user').then((userId) => {
      this.LoadProfile();
    }, this);

    // ???: App-wide message initialized here to eliminate circular dependency with free trial modal.
    app.on('user:handleFreeTrialModal').then((response) => {
      if (response === undefined) {
        this.Logout();
      } else {
        app.trigger('user:resetSessionTimeout');
        app.trigger('user:keepSessionAlive');
      }
    });
  }

  public IsAuthenticated = ko.computed<boolean>(() => {
    const retVal = !plootoUtils.IsNullOrUndefined(this.profile());
    return retVal;
  });

  public HasNewEmail = ko.computed<boolean>(() => {
    if (plootoUtils.IsNullOrUndefined(this.profile())) {
      return false;
    }
    if (plootoUtils.IsNullOrUndefined(this.profile().newEmail)) {
      return false;
    }

    return !plootoUtils.IsNullOrUndefined(this.profile().newEmail());
  });

  public IsEmailVerified = ko.computed<boolean>(() => {
    if (!this.IsAuthenticated()) {
      return false;
    }
    if (plootoUtils.IsNullOrUndefined(this.profile().emailVerified)) {
      return false;
    }
    return this.profile().emailVerified() === true;
  });

  public DisplayName = ko.computed<string>(() => {
    let retVal = '';
    if (!this.IsAuthenticated()) {
      return retVal;
    }

    if (
      ko.isObservable(this.profile().fullName) &&
      !plootoUtils.IsNullOrUndefined(this.profile().fullName())
    ) {
      retVal = this.profile().fullName();
    }

    if (retVal.length > 0) {
      return retVal;
    }
    return this.profile().email();
  });

  public DisplayFirstName = ko.computed<string>(() => {
    const displayName: string = this.DisplayName();

    const splitName = displayName.split(' ');
    if (splitName.length > 0) {
      return splitName[0];
    }

    return displayName;
  });

  public DisplayEmail = ko.computed<string>(() => {
    let retVal = '';
    if (!this.IsAuthenticated()) {
      return retVal;
    }

    if (
      ko.isObservable(this.profile().email) &&
      !plootoUtils.IsNullOrUndefined(this.profile().email())
    ) {
      retVal = this.profile().email();
    }

    if (retVal.length > 0) {
      return retVal;
    }
    return this.profile().email();
  });

  public UserId = ko.computed<string>(() => {
    if (!this.IsAuthenticated()) {
      return undefined;
    }
    return this.profile().id();
  });

  public ChatSecureHash = ko.computed<string>(() => {
    if (!this.IsAuthenticated()) {
      return undefined;
    }
    return this.profile().chatSecureHash();
  });

  public DisplayIcon = ko.computed<string>(() => {
    if (!this.IsAuthenticated()) {
      return '';
    }
    if (plootoUtils.IsNullOrUndefined(this.profile().icon)) {
      return 'Content/img/user/placeholder.jpg';
    }

    return this.profile().icon;
  });

  public LoadPreferences(): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.AuthorizedGet(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/profile`)
          .done((profileData) => {
            if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
              dfd.reject(profileData);
              return;
            }
            $.each(profileData, (key) => {
              if (ko.isObservable(this.profile()[key])) {
                this.profile()[key](profileData[key]);
              }
            });
            dfd.resolve();
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public UpdatePreferences(newProfile): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.AuthorizedPost(
          `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/profile`,
          newProfile
        )
          .done((profileData) => {
            if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
              dfd.reject(profileData);
              return;
            }
            $.each(profileData, (key) => {
              if (ko.isObservable(this.profile()[key])) {
                this.profile()[key](profileData[key]);
              }
            });
            dfd.resolve();
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public IsResetPassphraseValid(info: UserResetPassphraseValidationModel): Promise<boolean> {
    return ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/passphrase/validate`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );
  }

  public async ResetPassphrase(info: UserResetPassphraseModel): Promise<boolean> {
    await ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/passphrase/reset`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );
    return true;
  }

  public async SetPassPhrase(info: UserSetPassphraseEditModel): Promise<boolean> {
    const tokenData = await ApiService.post<UserLoginReturnModel>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/passphrase/set`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );
    this.SetAuthenticationToken(tokenData.sessionKey);
    return true;
  }

  public AddMembership(
    companyId: string,
    inviteId: string,
    inviteCode: string
  ): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.AuthorizedPut(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/memberships`, {
          companyId,
          inviteId,
          inviteCode,
        })
          .done((membershipsData) => {
            if (plootoUtils.IsNullOrUndefined(membershipsData) || membershipsData.error === true) {
              dfd.reject(membershipsData);
              return;
            }
            // todo:some strange typing conversion?
            const membership = ko.mapping.fromJS<Membership>(membershipsData);
            this.memberships.push(membership as any);

            dfd.resolve();
          })
          .fail((errorData) => {
            dfd.reject(errorData);
          });
      })
      .promise();
  }

  public LoadMemberships(): JQueryPromise<any> {
    return system.defer((dfd) => {
      this.AuthorizedGet(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/memberships`)
        .done((membershipsData) => {
          if (plootoUtils.IsNullOrUndefined(membershipsData) || membershipsData.error === true) {
            dfd.reject(membershipsData);
            return;
          }
          $.each(membershipsData, (index, membershipData) => {
            const existingMembership = $.grep<Membership>(
              this.memberships(),
              (checkMembership, checkMembershipIndex) => {
                const membershipId = checkMembership.id();

                return membershipId == membershipData.id;
              }
            );
            // TODO: perform check of the actual membership to make sure permissions/role hasnt changed
            if (existingMembership.length > 0) {
              existingMembership[0].Update(membershipData);
            } else {
              const membership = new Membership();
              membership.id(membershipData.id);
              membership.permissions(membershipData.permissions);
              membership.role(membershipData.role);
              membership.company(new CompanyProfile(membershipData.company));

              membership.featuresInTesting(membershipData.company.featuresInTesting);

              // @todo these need to be moved to CompanyProfile
              membership.isFastPaymentCapable(membershipData.isFastPaymentCapable);
              membership.isPaymentsQueueable(membershipData.isPaymentsQueueable);
              membership.isPaymentsCapable(membershipData.isPaymentsCapable);
              membership.isPendingBillingRedebit(membershipData.isPendingBillingRedebit);
              membership.mustCoverNegativeWalletBalance(
                membershipData.mustCoverNegativeWalletBalance
              );
              membership.isReauthenticationRequired(membershipData.isReauthenticationRequired);

              membership.companyAccountingFirmClientUserId(
                membershipData.companyAccountingFirmClientUserId
              );
              this.memberships.push(membership);
            }
          });

          this.memberships.remove((checkMembership: Membership) => {
            const matchedMembership = $.grep(
              membershipsData,
              (membership: any, membershipIndex) => membership.id == checkMembership.id()
            );
            // no membership found in new list, so remove it

            return matchedMembership.length == 0;
          });

          this.memberships.valueHasMutated();

          dfd.resolve();
        })
        .fail((errorData) => {
          dfd.reject(errorData);
        });
    });
  }

  public loadAccountingFirmMemberships(): JQueryPromise<any> {
    return system.defer((dfd) => {
      this.AuthorizedGet(
        `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/user/memberships/accountingFirmMemberships`
      )
        .done((accountingFirmMembershipsData) => {
          if (
            plootoUtils.IsNullOrUndefined(accountingFirmMembershipsData) ||
            accountingFirmMembershipsData.error === true
          ) {
            dfd.reject(accountingFirmMembershipsData);
            return;
          }

          accountingFirmMembershipsData.forEach((accountingFirmMembershipData) => {
            const existingMembership = this.accountingFirmMemberships().filter(
              (checkAccountingFirmMembership) =>
                checkAccountingFirmMembership.id() == accountingFirmMembershipData.id
            );
            if (existingMembership.length > 0) {
              existingMembership[0].Update(accountingFirmMembershipData);
            } else {
              const accountingFirmMembership = new Membership();
              accountingFirmMembership.id(accountingFirmMembershipData.id);
              accountingFirmMembership.permissions(accountingFirmMembershipData.permissions);
              accountingFirmMembership.role(accountingFirmMembershipData.role);
              accountingFirmMembership.company(
                new CompanyProfile(accountingFirmMembershipData.company)
              );

              accountingFirmMembership.isFastPaymentCapable(
                accountingFirmMembershipData.isFastPaymentCapable
              );
              accountingFirmMembership.isPaymentsQueueable(
                accountingFirmMembershipData.isPaymentsQueueable
              );
              accountingFirmMembership.isPaymentsCapable(
                accountingFirmMembershipData.isPaymentsCapable
              );
              accountingFirmMembership.isPendingBillingRedebit(
                accountingFirmMembershipData.isPendingBillingRedebit
              );
              accountingFirmMembership.mustCoverNegativeWalletBalance(
                accountingFirmMembershipData.mustCoverNegativeWalletBalance
              );

              accountingFirmMembership.companyAccountingFirmClientUserId(
                accountingFirmMembershipData.companyAccountingFirmClientUserId
              );
              this.accountingFirmMemberships.push(accountingFirmMembership);
            }
          });

          this.accountingFirmMemberships.remove((checkAccountingFirmMembership: Membership) => {
            const matchedAccountingFirmMembership = (
              accountingFirmMembershipsData as Array<any>
            ).filter(
              (accountingFirmMembership) =>
                accountingFirmMembership.id == checkAccountingFirmMembership.id()
            );

            return matchedAccountingFirmMembership.length === 0;
          });

          this.accountingFirmMemberships.valueHasMutated();

          dfd.resolve();
        })
        .fail((errorData) => {
          dfd.reject(errorData);
        });
    });
  }

  public LoadMembershipDetails(): JQueryPromise<
    Array<viewModels.CompanyMembershipDetailsViewModel>
  > {
    return system.defer((dfd) => {
      this.AuthorizedGet(
        `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/memberships/details`
      )
        .done((membershipDetails) => {
          if (
            plootoUtils.IsNullOrUndefined(membershipDetails) ||
            membershipDetails.error === true
          ) {
            dfd.reject(membershipDetails);
            return;
          }

          dfd.resolve(membershipDetails);
        })
        .fail((errorData) => {
          dfd.reject(errorData);
        });
    });
  }

  public loadMembershipStats(): JQueryPromise<Array<ClientListReportRow>> {
    return system.defer((dfd) => {
      this.AuthorizedGet(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/memberships/stats`)
        .done((membershipDetails) => {
          if (
            plootoUtils.IsNullOrUndefined(membershipDetails) ||
            membershipDetails.error === true
          ) {
            dfd.reject(membershipDetails);
            return;
          }

          dfd.resolve(membershipDetails);
        })
        .fail((errorData) => {
          dfd.reject(errorData);
        });
    });
  }

  public getAccountingFirmMemberships(): JQueryPromise<Array<Membership>> {
    return system.defer((dfd) => {
      this.AuthorizedGet(
        `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/user/memberships/accountingFirmMemberships`
      )
        .done((memberships: any) => {
          if (plootoUtils.IsNullOrUndefined(memberships) || memberships.error === true) {
            dfd.reject(memberships);
            return;
          }
          const accountingFirmMemberships: Array<Membership> = [];
          memberships.forEach((membershipData) => {
            const membership = new Membership();
            membership.id(membershipData.id);
            membership.permissions(membershipData.permissions);
            membership.role(membershipData.role);
            membership.company(new CompanyProfile(membershipData.company));
            membership.isFastPaymentCapable(membershipData.isFastPaymentCapable);
            membership.isPaymentsQueueable(membershipData.isPaymentsQueueable);
            membership.isPaymentsCapable(membershipData.isPaymentsCapable);
            membership.isPendingBillingRedebit(membershipData.isPendingBillingRedebit);
            membership.mustCoverNegativeWalletBalance(
              membershipData.mustCoverNegativeWalletBalance
            );
            membership.companyAccountingFirmClientUserId(
              membershipData.companyAccountingFirmClientUserId
            );

            accountingFirmMemberships.push(membership);
          });

          dfd.resolve(accountingFirmMemberships);
        })
        .fail((error) => dfd.reject(error));
    });
  }

  public LoadProfile(): JQueryPromise<any> {
    return system.defer((dfd) => {
      this.AuthorizedGet(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/profile`)
        .done((profileData) => {
          if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
            dfd.reject(profileData);
            return;
          }

          this.email = profileData.email;
          if (window.dataLayer != null) {
            window.dataLayer.push({
              '&uid': profileData.id,
            });
            window.dataLayer.push({
              dimension1: profileData.id,
            });
          }

          if (window.hj != null) {
            try {
              window.hj('identify', profileData.id, { userId: profileData.id });
            } catch (e) {
              // (empty)
            }
          }

          app.trigger('user:signedIn', {
            userId: profileData.id,
            email: profileData.email,
          });

          analytics.send('event', 'action', 'completed', 'Signed in');

          RealtimeService.subscribeToUser(profileData.id);

          if (this.profile() === undefined) {
            this.profile(ko.mapping.fromJS(profileData) as any);
          } else {
            ko.mapping.fromJS(profileData, {}, this.profile);
          }

          dfd.resolve();
        })
        .fail((errorData) => {
          dfd.reject(errorData);
        });
    });
  }

  public HasCapability(capability: string) {
    return $.inArray(capability, this.profile().userCapabilities()) >= 0;
  }

  public RegisterFull(info: UserRegisterFullModel): Promise<UserRegisterFullViewModel> {
    return ApiService.post<UserRegisterFullViewModel>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/registerFull`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );
  }

  // Attempts to resume session based on stored authentication token
  public ResumeSession(): JQueryPromise<UserLoginResponse> {
    const tokenStorage = $.localStorage;

    // check if there is any active tokens
    if (!tokenStorage.isSet(TOKEN_STORAGE_KEY)) {
      return system
        .defer<UserLoginResponse>((dfd) => {
          dfd.reject();
        })
        .promise();
    }

    return system
      .defer<UserLoginResponse>((dfd) => {
        $.when(
          this.LoadProfile(),
          this.LoadMemberships(),
          this.loadAccountingFirmMemberships()
        ).then(
          () => {
            RealtimeService.connect(this.UserId());
            dfd.resolve(UserLoginResponse.Successful);
          },
          (err) => {
            switch (err?.type) {
              case 'invalid.twoFactor.status.required':
                dfd.resolve(UserLoginResponse.TwoFactorAuthenticationRequired);
                return;
              case 'invalid.twoFactor.status.signupRequired':
                dfd.resolve(UserLoginResponse.TwoFactorSignupRequired);
                return;
              case 'invalid.twoFactor.status.complianceRequired':
                dfd.resolve(UserLoginResponse.TwoFactorComplianceRequired);
                return;
              default:
                break;
            }

            tokenStorage.remove(TOKEN_STORAGE_KEY);
            dfd.reject();
          }
        );
      })
      .promise();
  }

  public Logout(): JQueryPromise<any> {
    if (RealtimeService.isConnected()) {
      RealtimeService.disconnect(/* disposing: */ true);
    }

    return system
      .defer((dfd) => {
        this.AuthorizedPost(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/logout`).always(
          () => {
            this.SetAuthenticationToken(undefined);
            const tokenStorage = $.localStorage;
            tokenStorage.remove(TOKEN_STORAGE_KEY);

            EventsService.emit('user:logout');

            window.location.href = '/';

            dfd.resolve();
          }
        );
      })
      .promise();
  }

  public hasAuthenticationToken() {
    const tokenStorage = $.localStorage;

    return tokenStorage.isSet(TOKEN_STORAGE_KEY);
  }

  private SetAuthenticationToken(token: string) {
    const tokenStorage = $.localStorage;
    tokenStorage.set(TOKEN_STORAGE_KEY, token);
  }

  public GetAuthenticationToken(): string {
    const tokenStorage = $.localStorage;
    return tokenStorage.get(TOKEN_STORAGE_KEY);
  }

  public clearAuthenticationToken() {
    const tokenStorage = $.localStorage;
    tokenStorage.remove(TOKEN_STORAGE_KEY);
  }

  public LoginViaAuthenticationToken(token: string): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.SetAuthenticationToken(token);

        // using registred data restore the profile
        this.ResumeSession()
          .done((userLoginResponse) => {
            dfd.resolve(userLoginResponse);
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public Login(info): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        $.ajax({
          url: `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/login`,
          data: ko.toJSON(info),
          xhrFields: {
            withCredentials: true,
          },
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
        })
          .done((tokenData: IAuthenticationTokenData) => {
            if (plootoUtils.IsNullOrUndefined(tokenData) || tokenData.error === true) {
              dfd.reject(tokenData);
              return;
            }
            this.SetAuthenticationToken(tokenData.sessionKey);

            // using registered data restore the profile
            this.ResumeSession()
              .then((userLoginResponse: UserLoginResponse) => {
                dfd.resolve({
                  userLoginResponse,
                  tokenData,
                });
              })
              .fail(() => {
                dfd.reject();
              });
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public async VerifyEmail(email: string, code: string): Promise<EmailVerifiedViewModel> {
    return ApiService.post<EmailVerifiedViewModel>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/verifyEmail`,
      { body: { email, code } },
      false
    );
  }

  public async EmailVerifiedLogin(
    emailVerifiedLoginModel: viewModels.EmailVerifiedBindingModel
  ): Promise<{ userLoginResponse: UserLoginResponse; tokenData: IAuthenticationTokenData }> {
    const tokenData = await ApiService.post<IAuthenticationTokenData>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/emailVerifiedLogin`,
      { body: emailVerifiedLoginModel },
      false
    );

    this.SetAuthenticationToken(tokenData.sessionKey);

    const userLoginResponse = await this.ResumeSession();
    return { userLoginResponse, tokenData };
  }

  public async ResendQuickVerificationEmail(email: string): Promise<boolean> {
    await ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/resendQuickVerificationEmail`,
      { body: { email } },
      false
    );
    return true;
  }

  public async RegistrationComplete(info: UserEmailConfirmModel): Promise<IUserLoginResult> {
    const userLoginResult = await ApiService.post<IAuthenticationTokenData>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/registration/complete`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );

    analytics.send('event', 'User', 'Verified', 'Email');
    analytics.send('event', 'action', 'completed', 'Completed registration');

    this.SetAuthenticationToken(userLoginResult.sessionKey);

    // using registered data restore the profile
    const userLoginResponse = await this.ResumeSession();
    return { userLoginResponse, tokenData: userLoginResult };
  }

  public async RegistrationCheck(email: string, code: string): Promise<IRegistrationCheck> {
    const response = await ApiService.post<IRegistrationCheck>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/registration/check`,
      { body: { email, code } },
      false
    );
    analytics.send('event', 'action', 'completed', 'Clicked email verification link');
    return response;
  }

  public async VerificationComplete(userId: string, code: string): Promise<void> {
    await ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/verification/complete`,
      { body: { userId, code } },
      false
    );
  }

  public ResendVerificationEmail(): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.AuthorizedPost(
          `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/verification/resend`
        )
          .then((response) => {
            if (plootoUtils.IsNullOrUndefined(response) || response.error === true) {
              dfd.reject(response);
              return;
            }
            dfd.resolve();
          })
          .fail((error) => {
            dfd.reject();
          });
      })
      .promise();
  }

  public async ResendVerificationEmailTo(info: UserReSendVerificationModel): Promise<void> {
    await ApiService.post(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/verification/resend`,
      { body: plootoUtils.CSRFTokenAuthorize(info) },
      false
    );
  }

  private authorizedHttpHeaders(): Record<string, string> {
    const headers = {
      ...apimHttpHeader,
      ...getAuthorizationHeader(),
      'X-Google-Analytics-Client-Id': this.getGoogleAnalyticsClientId(),
    };
    return headers;
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedGet(
    path: string,
    data?: unknown,
    headers?: unknown,
    withCredentials = true
  ): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    const ajaxParams: JQueryAjaxSettings = {
      url: path,
      data: ko.toJSON(data),
      type: 'GET',
      contentType: 'application/json',
      dataType: 'json',
      headers: this.authorizedHttpHeaders(),
      xhrFields: {
        withCredentials,
      },
    };

    if (headers) {
      $.each(headers, (headerName, header) => {
        if (!ajaxParams.headers[headerName]) {
          ajaxParams.headers[headerName] = header;
        }
      });
    }

    return $.ajax(ajaxParams);
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedGetFromUri(path: string, data?: unknown, headers?: unknown): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    const ajaxParams: JQueryAjaxSettings = {
      url: path,
      data,
      type: 'GET',
      contentType: 'application/json',
      dataType: 'json',
      headers: this.authorizedHttpHeaders(),
      xhrFields: {
        withCredentials: true,
      },
    };

    if (headers) {
      $.each(headers, (headerName, header) => {
        if (!ajaxParams.headers[headerName]) {
          ajaxParams.headers[headerName] = header;
        }
      });
    }

    return $.ajax(ajaxParams);
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedPost(path: string, data?: unknown): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    return this.checkTimeout(
      path,
      $.ajax({
        url: path,
        xhrFields: {
          withCredentials: true,
        },
        data: ko.toJSON(data),
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        headers: this.authorizedHttpHeaders(),
      })
    );
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedPut(
    path: string,
    data?: unknown,
    withCredentials = true,
    dataType = 'json'
  ): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    return this.checkTimeout(
      path,
      $.ajax({
        url: path,
        data: ko.toJSON(data),
        xhrFields: {
          withCredentials,
        },
        type: 'PUT',
        contentType: 'application/json',
        dataType,
        headers: this.authorizedHttpHeaders(),
      })
    );
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedUpload(
    path: string,
    formData: FormData,
    options?: { type?: JQueryAjaxSettings['type'] }
  ): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    return this.checkTimeout(
      path,
      $.ajax({
        url: path,
        data: formData,
        xhrFields: {
          withCredentials: true,
        },
        type: options?.type ?? 'POST',
        headers: this.authorizedHttpHeaders(),
        cache: false,
        contentType: false,
        processData: false,
      })
    );
  }

  /** @deprecated Do not use. Use `ApiService` instead. */
  public AuthorizedDelete(path: string, data?: unknown): JQueryPromise<any> {
    app.trigger('user:resetSessionTimeout');

    return this.checkTimeout(
      path,
      $.ajax({
        url: path,
        data: ko.toJSON(data),
        xhrFields: {
          withCredentials: true,
        },
        type: 'DELETE',
        contentType: 'application/json',
        dataType: 'json',
        headers: this.authorizedHttpHeaders(),
      })
    );
  }

  private getGoogleAnalyticsClientId() {
    const { gaClientId } = window as any;
    if (gaClientId && gaClientId != 'undefined') {
      return gaClientId;
    }
    return undefined;
  }

  public checkTimeout(path: string, ajaxCall: JQueryXHR): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        ajaxCall
          .then((data) => {
            if (
              data &&
              data.error &&
              data.type === 'invalid.authentication' &&
              path.indexOf('/user/logout') === -1
            ) {
              // While we should await this, the previous implementation had a bug that made it not
              // actually wait, so we'll keep that rather than plumbing a callback through the
              // Durandal event bus.
              app.trigger('user:showSessionTimeout');
            }

            dfd.resolve(data);
          })
          .fail((data) => {
            dfd.reject(data);
          });
      })
      .promise();
  }

  public updateCountry(country: string): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        this.AuthorizedPost(
          `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/user/profile/country`,
          country
        )
          .then((result) => {
            if (plootoUtils.IsNullOrUndefined(result) || result.error === true) {
              dfd.reject(result);
              return;
            }

            dfd.resolve(result);
          })
          .fail((errorData) => {
            dfd.reject(errorData);
          });
      })
      .promise();
  }

  /** Gets the country using the ip of the CURRENT REQUEST,
   * with the new GeolocationAPI currently utilizing MaxMind
   * and falling back to User registration country */
  public getRequestCountry = () => this.getCurrentCountry(false);

  private static geolocationApiEndpoint = 'user/profile/currentCountry';

  /** Gets the country using the ip from the SESSION (at TIME OF LOGIN),
   * limiting the country to SUPPORTED COUNTRIES ['US','CA']
   * using the older GEO2IP implementation/mapping
   * and falling back to User registration country */
  public getSessionCountry = () => this.getCurrentCountry();

  private static supportedCountriesGeolocationEndpoint = 'user/profile/currentLoginCountry';

  private getCurrentCountry(limitToSupportedCountries = true): JQueryPromise<any> {
    const endpoint = limitToSupportedCountries
      ? UserService.supportedCountriesGeolocationEndpoint
      : UserService.geolocationApiEndpoint;

    return system
      .defer((dfd) => {
        this.AuthorizedGet(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/${endpoint}`)
          .then((country) => {
            if (plootoUtils.IsNullOrUndefined(country) || country.error === true) {
              dfd.reject(country);
              return;
            }

            dfd.resolve(country);
          })
          .fail((errorData) => {
            dfd.reject(errorData);
          });
      })
      .promise();
  }
}

const Instance = new UserService();

export {
  UserProfile,
  CompanyMembershipStatsPendingApprovalViewModel,
  PendingCompanyPaymentReportRow,
  UserValidatedCompanyCapabilityReportRow,
  ClientListReportRow,
  CompanyBillingCycleViewModel,
  CompanyProfile,
  IPlootoApiCall,
  IAuthenticationTokenData,
  IUserLoginResult,
  UserLoginResponse,
  FeaturesInTesting,
  IRegistrationCheck,
  Membership,
  UserService,
  CompanySubscriptionFeaturesViewModel,
  Instance,
};
