import {
  buttonClasses,
  formControlLabelClasses,
  linkClasses,
  paperClasses,
  tablePaginationClasses,
  TransitionsOptions,
  typographyClasses,
} from '@mui/material';
import { enUS as coreEnglishLocalizations } from '@mui/material/locale';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { TypographyUtils } from '@mui/material/styles/createTypography';
import { alpha, darken, lighten } from '@mui/system/colorManipulator';
import { enUS as dataGridEnglishLocalizations } from '@mui/x-data-grid-premium/locales';
import { enUS as datePickersEnglishLocalizations } from '@mui/x-date-pickers/locales';
// See: https://mui.com/x/react-date-pickers/base-concepts/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation';

import AvenirNextWoff2 from '../Content/fonts/AvenirNextLTPro-Regular.woff2';

import { a, label, p } from './bootstrapReset';

declare module '@mui/material/styles' {
  interface Palette {
    primarySubdued: Palette['primary'];
    secondarySubdued: Palette['secondary'];
    errorSubdued: Palette['error'];
    warningSubdued: Palette['warning'];
    infoSubdued: Palette['info'];
    successSubdued: Palette['success'];
    inverse: Palette['secondary'];
    // Marketing colors
    cream: Palette['secondary'];
    midnight: Palette['secondary'];
    shamrock: Palette['secondary'];
  }

  interface PaletteOptions {
    primarySubdued: PaletteOptions['primary'];
    secondarySubdued: PaletteOptions['secondary'];
    errorSubdued: PaletteOptions['error'];
    warningSubdued: PaletteOptions['warning'];
    infoSubdued: PaletteOptions['info'];
    successSubdued: PaletteOptions['success'];
    inverse: PaletteOptions['secondary'];
    // Marketing colors
    cream: PaletteOptions['secondary'];
    midnight: PaletteOptions['secondary'];
    shamrock: PaletteOptions['secondary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    primarySubdued: true;
    secondarySubdued: true;
    errorSubdued: true;
    warningSubdued: true;
    infoSubdued: true;
    successSubdued: true;
    inverse: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    midnight: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    midnight: true;
    shamrock: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    midnight: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    shamrock: true;
  }
}

/**
 * Plooto colour palette
 *
 * @see {@link https://www.figma.com/file/GHFwYvlUJC9am3ZaVhYrNL/Design-System-MUI-v5.14.0?type=design&node-id=6649-50219&mode=design&t=k4OQJHGXqKabApkd-0 Figma}
 */
const PLOOTO_COLOURS = {
  Black: '#2A2A2A',
  White: '#FFFFFF',
  Grey50: '#FAFAFA',
  Grey100: '#F5F5F5',
  Grey200: '#EEEEEE',
  Grey300: '#E0E0E0',
  Grey400: '#BDBDBD',
  Grey500: '#9E9E9E',
  Grey600: '#757575',
  Grey700: '#616161',
  Grey800: '#424242',
  Grey900: '#212121',
  PrimaryLight: '#1D43E4',
  PrimaryMain: '#173BD9',
  PrimaryDark: '#002FCD',
  SecondaryLight: '#BDBFC1',
  SecondaryMain: '#747A82',
  SecondaryDark: '#42474D',
};

/** Marketing colours, used for flavour in the app. */
const MARKETING_COLORS = {
  Cream: '#F4F2EC',
  Shamrock: '#00BD37',
  Midnight: '#001234',
};

/** Plooto font stack */
const PLOOTO_FONT_FAMILY = '"Avenir Next", "Helvetica", "Arial", sans-serif';

/** Avenir Next @font-face decl */
const avenirNextFontFace = `
  @font-face {
    font-family: 'AvenirNext';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      local('AvenirNext'),
      local('AvenirNext-Regular'),
      url('${AvenirNextWoff2}') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;

/** Default MUI HTML font size */
const MUI_HTML_FONT_SIZE = 16;
/** Default Bootstrap HTML font size */
const BOOTSTRAP_HTML_FONT_SIZE = 14;

/** Base typography settings */
const baseTypography: ThemeOptions['typography'] = {
  /* NOTE: Until we stop using Bootstrap, we need to account for the font sizes it sets
  (14px in `html`). */
  htmlFontSize: MUI_HTML_FONT_SIZE,
  fontSize: BOOTSTRAP_HTML_FONT_SIZE,
};

const {
  palette: { augmentColor, ...basePalette },
  typography: { pxToRem: muiPxToRem },
  spacing,
} = createTheme({ typography: baseTypography });

/**
 * Scale a px value, using the ratio between the MUI and Bootstrap font sizes as the scale factor.
 * @param px Size in pixels against the MUI baseline (16px).
 * @returns Size in pixels against the Bootstrap baseline (14px).
 */
const pxToScale = (px: number) => (px * BOOTSTRAP_HTML_FONT_SIZE) / MUI_HTML_FONT_SIZE;

/**
 * Customized version of MUI's `pxToRem` function using the MUI HTML font size as the base and
 * adjusting toward the Bootstrap font size.
 * @param px Size in pixels.
 * @returns Size in rems.
 */
const pxToRem: TypographyUtils['pxToRem'] = (px) => muiPxToRem(pxToScale(px));

/** Transitions with all durations set to 0. Helps expedite tests */
const disableTransitions: TransitionsOptions = {
  duration: {
    shortest: 0,
    shorter: 0,
    short: 0,
    standard: 0,
    complex: 0,
    enteringScreen: 0,
    leavingScreen: 0,
  },
};

/** Subdued colors are 75% lighter than their main counterparts. */
const SUBDUED_LIGHTEN_COEFFICIENT = 0.75;

/**
 * Plooto MUI theme object
 *
 * @see {@link https://www.figma.com/file/4AVsbZxsJm1Zo3vLlQ3W3X/ Plooto design system on Figma}
 */
const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      light: PLOOTO_COLOURS.PrimaryLight,
      main: PLOOTO_COLOURS.PrimaryMain,
      dark: PLOOTO_COLOURS.PrimaryDark,
    },
    secondary: {
      light: PLOOTO_COLOURS.SecondaryLight,
      main: PLOOTO_COLOURS.SecondaryMain,
      dark: PLOOTO_COLOURS.SecondaryDark,
    },
    primarySubdued: augmentColor({
      color: { main: lighten(PLOOTO_COLOURS.PrimaryMain, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'primarySubdued',
    }),
    secondarySubdued: augmentColor({
      color: { main: lighten(PLOOTO_COLOURS.SecondaryMain, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'secondarySubdued',
    }),
    errorSubdued: augmentColor({
      color: { main: lighten(basePalette.error.main, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'errorSubdued',
    }),
    warningSubdued: augmentColor({
      color: { main: lighten(basePalette.warning.main, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'warningSubdued',
    }),
    infoSubdued: augmentColor({
      color: { main: lighten(basePalette.info.main, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'infoSubdued',
    }),
    successSubdued: augmentColor({
      color: { main: lighten(basePalette.success.main, SUBDUED_LIGHTEN_COEFFICIENT) },
      name: 'successSubdued',
    }),
    inverse: {
      main: PLOOTO_COLOURS.Black,
      contrastText: PLOOTO_COLOURS.White,
    },
    // Marketing colors
    cream: augmentColor({
      color: { main: MARKETING_COLORS.Cream },
      name: 'cream',
    }),
    midnight: augmentColor({
      color: { main: MARKETING_COLORS.Midnight },
      name: 'midnight',
    }),
    shamrock: augmentColor({
      color: { main: MARKETING_COLORS.Shamrock },
      name: 'shamrock',
    }),
    grey: {
      50: PLOOTO_COLOURS.Grey50,
      100: PLOOTO_COLOURS.Grey100,
      200: PLOOTO_COLOURS.Grey200,
      300: PLOOTO_COLOURS.Grey300,
      400: PLOOTO_COLOURS.Grey400,
      500: PLOOTO_COLOURS.Grey500,
      600: PLOOTO_COLOURS.Grey600,
      700: PLOOTO_COLOURS.Grey700,
      800: PLOOTO_COLOURS.Grey800,
      900: PLOOTO_COLOURS.Grey900,
    },
    text: {
      primary: PLOOTO_COLOURS.Black,
    },
    action: {
      // Improve disabled button backgrounds visibility on dark backgrounds.
      disabledBackground: PLOOTO_COLOURS.Grey300,
    },
  },
  typography: {
    ...baseTypography,
    fontFamily: PLOOTO_FONT_FAMILY,
    h1: {
      fontSize: pxToRem(48),
      fontWeight: 700,
      lineHeight: '1.167',
    },
    h2: {
      fontSize: pxToRem(34),
      fontWeight: 400,
      lineHeight: '1.235',
    },
    h3: {
      fontSize: pxToRem(24),
      fontWeight: 500,
      lineHeight: '1.334',
    },
    h4: {
      fontSize: pxToRem(20),
      fontWeight: 600,
      lineHeight: '1.60',
    },
    subtitle1: {
      fontSize: pxToRem(16),
      fontWeight: 600,
      lineHeight: '1.75',
    },
    subtitle2: {
      fontSize: pxToRem(14),
      fontWeight: 600,
      lineHeight: '1.57',
    },
    body1: {
      fontSize: pxToRem(16),
      lineHeight: '1.50',
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: '1.43',
    },
    caption: {
      fontSize: pxToRem(12),
      lineHeight: '1.43',
    },
    button: {
      fontSize: pxToRem(14),
      fontWeight: 600,
      textTransform: 'none',
    },
    // @ts-expect-error pxToRem is on TypographyUtils, which is not on TypographyOptions.
    pxToRem,
  },
  shape: {
    borderRadius: 3,
  },
  transitions: process.env.NODE_ENV === 'test' ? disableTransitions : undefined,
};

/**
 * MUI theme component overrides
 */
const componentOverrides: Partial<Theme['components']> = {
  MuiCssBaseline: {
    // @ts-expect-error it works fine
    styleOverrides: [
      `
      ${avenirNextFontFace}

      body {
        font-size: ${pxToRem(16)};
      }

      // XXX: For .${paperClasses.elevation1}, box-shadow must come before border. I do not know why.
      .${paperClasses.elevation1} {
        // We require !important here as slotted versions have higher precedence (e.g. TableContainer)
        box-shadow: none !important;
        border: 1px solid ${darken(PLOOTO_COLOURS.Grey300, 0.1)};
      }
      `,
      {
        // Bootstrap global CSS reset goes here, for component-wide resets
        [`.${typographyClasses.root}`]: p,
        [`.${tablePaginationClasses.selectLabel}`]: p,
        [`.${tablePaginationClasses.displayedRows}`]: p,
        [`.${buttonClasses.root}`]: a,
        [`.${linkClasses.root}`]: a,
        [`.${formControlLabelClasses.root}`]: label,
      },
    ],
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        lineHeight: '1',
      },
      sizeSmall: {
        height: spacing(3.75),
        padding: spacing(1),
      },
      sizeMedium: {
        height: spacing(4.75),
        padding: spacing(1.25),
      },
      sizeLarge: {
        height: spacing(5.25),
        padding: spacing(1.5),
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        lineHeight: '1',
      },
      contained: {
        '&:hover': {
          // Overwrite Bootstrap global style for <a> link buttons.
          color: PLOOTO_COLOURS.White,
        },
      },
      containedInherit: {
        // Overwrite default generated white color.
        '&:hover': {
          color: 'inherit',
        },
      },
      containedSizeSmall: {
        height: spacing(3.75),
        padding: spacing(1, 1.5),
      },
      containedSizeMedium: {
        height: spacing(4.75),
        padding: spacing(1.25, 2.5),
      },
      containedSizeLarge: {
        height: spacing(5.25),
        padding: spacing(1.5, 3),
      },
      outlinedSizeSmall: {
        height: spacing(3.75),
        padding: spacing(1, 1.5),
      },
      outlinedSizeMedium: {
        height: spacing(4.75),
        padding: spacing(1.25, 2.5),
      },
      outlinedSizeLarge: {
        height: spacing(5.25),
        padding: spacing(1.5, 3),
      },
      iconSizeSmall: {
        '& > *:nth-of-type(1)': {
          fontSize: pxToScale(18),
        },
      },
      iconSizeMedium: {
        '& > *:nth-of-type(1)': {
          fontSize: pxToScale(20),
        },
      },
      iconSizeLarge: {
        '& > *:nth-of-type(1)': {
          fontSize: pxToScale(22),
        },
      },
      text: {
        whiteSpace: 'nowrap',
      },
    },
    variants: [
      {
        props: { color: 'inherit', variant: 'text' },
        style: {
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    ],
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: spacing(2),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: spacing(2),
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(16),
        height: pxToRem(32),
      },
      sizeSmall: {
        height: pxToRem(24),
      },
      icon: {
        height: pxToRem(24),
      },
      iconSmall: {
        height: pxToRem(16),
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      variant: 'h4',
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: muiPxToRem(12),
        lineHeight: '1.67',
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      slotProps: { paper: { elevation: 1 } },
    },
  },
  MuiPopover: {
    defaultProps: {
      slotProps: { paper: { elevation: 1 } },
    },
  },
  MuiPaper: {
    // XXX: DO NOT add styleOverrides for MuiPaper. MUI has a bug with Accordion that introduces
    // corruption into the Emotion=>Stylis render process when this is used. Not sure why, but it
    // looks like they're doing some syntax concatenation and it breaks for this specific
    // combination. Instead, we customize using the well-known class names in MuiBaseline.
  },
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      select: {
        '& [class^=MuiTypography-root]': {
          fontWeight: 400,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: alpha(PLOOTO_COLOURS.Black, 0.9),
        fontSize: muiPxToRem(14),
        fontWeight: 400,
        lineHeight: '1.43',
        padding: spacing(1),
        maxWidth: 300,
      },
    },
  },
  // Labs components
  MuiDatePicker: {
    defaultProps: {
      showDaysOutsideCurrentMonth: true,
    },
  },
};

/** Generated Plooto MUI theme */
const plootoTheme = createTheme(
  { ...baseTheme, components: componentOverrides },
  coreEnglishLocalizations,
  datePickersEnglishLocalizations,
  dataGridEnglishLocalizations
);

export {
  // Scary name to discourage misuse.
  // eslint-disable-next-line camelcase
  plootoTheme as PlootoTheme_DoNotUse_ThisIsOnlyForSettingUpTheMaterialTheme_CodeReviewerPleaseEnforceThis,
  // Scary name to discourage misuse.
  // eslint-disable-next-line camelcase
  PLOOTO_COLOURS as PlootoColors_DoNotUse_ThisIsOnlyForSettingUpTheMaterialTheme_CodeReviewerPleaseEnforceThis,
  // Scary name to discourage misuse.
  // eslint-disable-next-line camelcase
  PLOOTO_FONT_FAMILY as PlootoFontFamily_DoNotUse_ThisIsOnlyForSettingUpTheMaterialTheme_CodeReviewerPleaseEnforceThis,
};
