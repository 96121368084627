import XDate from 'xdate';

/** Interchange formats, for use with our API. */
export enum ApiDateFormat {
  /** ISO short date. @example "2023-09-07" */
  DateOnly = 'yyyy-MM-dd',

  /** ISO long date. @example "2023-09-07T17:30:00Z" */
  DateAndTime = 'u',

  /** CPA closed day. @example "23-09-07" */
  CpaClosedDay = 'yy-MM-dd',
}

/** Display formats for use with in our UI. */
export enum DisplayDateFormat {
  /** Short date. @example "07 Sep 2023" */
  ShortDate = 'dd MMM yyyy',

  /** Long date. @example "Sep 07 2023" */
  // XXX(PLP-32094): Should use full month name.
  LongDate = 'MMM dd yyyy',

  /** Long date and time. @example "Sep 07 2023, 05:30 PM" */
  LongDateAndTime = 'MMM dd yyyy, hh:mm TT',

  /** Calendar month. @example "September 2023" */
  CalendarMonth = 'MMMM yyyy',

  /** Due date. @example "01 Sep 2023 @ 05:00 PM" */
  DueDate = 'dd MMM yyyy @ hh:mm TT',
}

/**
 * Converts a Date value received from the **server** (UTC) to a string. Use this if you need to
 * display a UTC without adjusting it to the user's timezone, or if you need to convert from local
 * time to server time (e.g. a payment due date).
 *
 * If you are only showing a date, and it came from the API, you probably want to use this one.
 */
export function formatServerDate(value: Date, format: DisplayDateFormat | ApiDateFormat): string {
  // Ideally we would not have to do this, but we are not using strictNullChecks, so garbage values
  // can get through to here.
  if (!(value instanceof Date)) {
    return '';
  }

  // Ideally we would not have to do this, but some callers pass junk strings into the Date
  // constructor and rely on it for validation.
  if (Number.isNaN(value.getTime())) {
    return '';
  }

  return new XDate(value).toUTCString(format);
}

/**
 * Converts a Date value created on the **client** (e.g. Eastern Time) to a string. Use this if you
 * need to display a date created locally (e.g. through a date picker), or if you need to convert
 * from server time to local time (e.g. a payment approval date).
 *
 * If you are showing a date and time, you probably want to use this one.
 */
export function formatLocalDate(value: Date, format: DisplayDateFormat | ApiDateFormat): string {
  // Ideally we would not have to do this, but we are not using strictNullChecks, so garbage values
  // can get through to here.
  if (!(value instanceof Date)) {
    return '';
  }

  // Ideally we would not have to do this, but some callers pass junk strings into the Date
  // constructor and rely on it for validation.
  if (Number.isNaN(value.getTime())) {
    return '';
  }

  return new XDate(value).toString(format);
}

/**
 * Converts a server date to local date by shifting it by the local timezone offset. In some places,
 * the backend returns invalid gibberish dates like 2024-09-16T00:00:00.000+00:00, which are
 * intended to be "start of day EST" but are actually "start of day UTC". Forcefully shift this into
 * local time so that it is "start of day" in local time at least.
 */
export function parseServerDateButShiftToLocalTimeZoneBecauseTheBackEndIsWrong(
  value: string
): Date {
  if (value == null) {
    return null;
  }

  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    return null;
  }

  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
}
